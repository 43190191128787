import React,{useState} from "react";
import {Box, Button, Flex, Text} from "@chakra-ui/react";
import {VOCAB_RUSSIE, VOCAB_UKRAINE} from "./data";
import {getNameFromTagId} from "../../../tag/tools";
import Link from "gatsby-link";
import {SearchIcon} from "@chakra-ui/icons";

const MethodoUkraine = ({})=>{
const listTagId = [13,26,39,8,11,18,1,7]

    const [showVocab,setShowVocab] =useState(false)

    return <Box p={[1,2,3]} fontSize={"19px"}>

        <Box mt={2}>
            <Text  fontWeight={"bold"}>.:. Les candidats sélectionnées dans cette étude sont les huit principaux en intentions de vote à la date du 28 Février :
            </Text>
                <Text p={[1,2]}>
                 {listTagId.map((tagId)=>
                     <Box as={"span"} p={["1px","2px"]} m={["1px","2px"]}
                                      bg={"white"}
            borderRadius={"3px"} borderColor={"bg.900"} borderWidth={"1px"} borderStyle={"solid"}> {getNameFromTagId(tagId)}</Box>)}
            </Text>
        </Box>

        <Box mt={2}>
              <Text  fontWeight={"bold"}> .:.
            Lorsqu'on parle d'activité politique générée par un candidat. Nous sélectionnons l'ensemble des tweets et retweets de ce candidat ansi que tous les retweets que ceux-ci génèrent.
              </Text>
          </Box>
        <Box mt={2}>
            <Text fontWeight={"bold"}>.:. Un tweet parle/évoque la thématique Ukraine/Russie s'il contient : </Text>
            {!showVocab && <Flex height={"100px"} justifyContent={"center"} flexDirection={"column"} alignItems={"flex-start"} p={[2,4,6]}>
                <Button colorScheme={"gray"} variant='outline' rightIcon={<SearchIcon/>} onClick={() => {
            setShowVocab(true)
        }}  color={"text.200"} fontWeight={"body"}> Afficher le vocabulaire</Button> </Flex>}
            {showVocab && <Box><Flex flexWrap={"wrap"} fontSize={["12px","14px","16px"]} p={[1,2]}>
        {VOCAB_UKRAINE.map((t)=><Box  p={["0px","2px"]} m={["1px","2px"]}
                                      bg={"white"}
            borderRadius={"3px"} borderColor={"bg.900"} borderWidth={"1px"} borderStyle={"solid"}
            >{t}</Box>)}
        </Flex>

        <Flex flexWrap={"wrap"} fontSize={["12px","14px","16px"]} p={[1,2]}>
        {VOCAB_RUSSIE.map((t)=><Box  p={["0px","2px"]} m={["1px","2px"]}
                                      bg={"white"}
            borderRadius={"3px"} borderColor={"bg.900"} borderWidth={"1px"} borderStyle={"solid"}
            >{t}</Box>)}
        </Flex></Box>}

        </Box>

          <Box mt={2}>
              <Text  fontWeight={"bold"}>.:.
                  En savoir plus sur la <Link to={"/methodologie"} > <Box textDecoration={"underline"} as={"span"}>méthodologie générale</Box></Link>
            </Text>
        </Box>

    </Box>
}
export default MethodoUkraine
