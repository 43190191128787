import React from "react";
import {Avatar, Box, Flex, Text, Tooltip} from "@chakra-ui/react";
import {LIST_CANDIDAT_ID} from "./data";
import {getNameFromTagId, getUrlImgFromTagId} from "../../../tag/tools";
import {InfoIcon} from "@chakra-ui/icons";
import PGraphNote from "../../tools/PGraphNote";

const widthValue = "120px"
const widthAvatar = "90px"
const heightRow = "60px"
const TableDesciptif = ({jsonData})=>{

    const jsonDataTagDefault = {'nb_m': 0, 'retweet_m': 0, 'nb_f': 0, 'nb_fweek': 0}

    return <Box>
        <Box overflowX={"auto"}  m={[1,2]}>
        <Flex justifyContent={"center"}   minWidth={450}>
        <Box width={360 + 90} >
            <Flex alignItems={"end"}>
                 <Box w={widthAvatar}></Box>
                <ItemTitle  c={"par jour"} v={<Box as={"span"}>Nombre<br/>Posts <Tooltip label='Nombre de tweets,commentaires envoyés depuis le compte de cette personnalité' aria-label='A tooltip'>
                      <InfoIcon ml={1} mb={'4px'} color={"text.50"}/>
                    </Tooltip></Box>}/>
                <ItemTitle v={<Box as={"span"}>Nombre <br/>retweets</Box>} c={"par post"}/>
                <ItemTitle v={<Box as={"span"}>Nombre de<br/>retweeter<Tooltip label='Nombre de comptes utilisateurs différents ayant au moins retweeté une fois le candidat' aria-label='A tooltip'>
                      <InfoIcon ml={1} mb={'4px'} color={"text.50"}/>
                    </Tooltip></Box>}   c={"par semaine"}/>

            </Flex>
            {LIST_CANDIDAT_ID.sort((a,b) => {
                const val_a = jsonData.hasOwnProperty(a)?jsonData[a]["nb_fweek"]:0
                const val_b = jsonData.hasOwnProperty(b)?jsonData[b]["nb_fweek"]:0

                return val_b - val_a}).map((tagId,numRow) =><RowTable numRow={numRow} tagId={tagId} jsonData={jsonData.hasOwnProperty(tagId)?jsonData[tagId]:jsonDataTagDefault}/>)}
        </Box>
            </Flex>
    </Box>
        <PGraphNote content={<Box><Text>Ces données sont calculées sur la période du 1er janvier 2022 au 10 mars 2022</Text>
        <Text>Un post  =  tweet ou commentaire envoyés depuis le compte du candidat</Text>
        <Text>Nb Retweeters par semaine  =  Nombre de comptes utilisateurs différents ayant au moins retweeté une fois le candidat</Text>
        </Box>}/>
    </Box>
}

const ItemTitle = ({v,c})=>{
    return  <Box textAlign={"center"} w={widthValue} lineHeight={1}><Box textTransform={"uppercase"} color={"text.200"}>
        {v}</Box>
        <Box color={"text.200"} >{c}</Box>
            </Box>
}

const RowTable = ({tagId,jsonData,numRow})=>{
    const {nb_m,retweet_m,nb_fweek} = jsonData
    const bgRow = (numRow%2 ==1)?"bg.900":"bg.500"
return <Flex  bg={bgRow} alignItems={"center"} justifyContent={"center"} p={[1]} h={heightRow}>
        <Box  width={widthAvatar} textAlign={"center"}><Avatar  src={getUrlImgFromTagId(tagId,100)}  />
            {/*<Box fontSize={"14px"} color={"text.200"}>{getNameFromTagId(tagId)}</Box>*/}
        </Box>
    <Flex >
        <ValueCell v={nb_m}/>
        <ValueCell v={retweet_m}/>
        <ValueCell v={nb_fweek}/>
    </Flex>
    </Flex>
}
const ValueCell = ({v})=> {
    return <Box textAlign={"center"} p={[0]} fontWeight={"bold"} w={widthValue} fontSize={["16px","18px","22px"]}>{v}</Box>
}

export default TableDesciptif