import React, {useMemo} from "react";
import {Box, Container, Heading, Stack, Text} from "@chakra-ui/react";
import {LOG} from "../tools";
import PageStartUkraine from "./analyse/startUkraine/PageStartUkraine";
import GoBack from "./global/GoBack";
import PageNetwork from "./analyse/network/PageNetwork";

const AnalysePage = ({jsonAnalyse})=>{

    const {title,id,authors,date} = jsonAnalyse
    const dateObject = new Date(date)
    const PageContent = useMemo(()=>{
      switch(id){
          case "startUkraine":
              return <PageStartUkraine jsonAnalyse={jsonAnalyse} />
          case "network":
              return <PageNetwork jsonAnalyse={jsonAnalyse} />
          default:
              return <Box>Article en cours d'écriture</Box>
      }
    },[id])


    return <Container maxW="container.xl" sx={{margin: `10px auto`,
                pl:['2px','10px','10px'],
                pr:['2px','10px','10px'],
            }}>
        <Heading
            mt={['16px','64px','128px']} p={1} fontFamily={"body"}>{title}</Heading>
            <Text p={0} fontWeight={600} color={"text.300"} fontSize={"20px"} p={[1,2]}>
                {authors[0]} <Box as={"span"} fontWeight={"400"} fontSize={"17px"}> - {dateObject.toLocaleDateString()}</Box>
            </Text>
        {PageContent}

        <GoBack/>
    </Container>
}

export default AnalysePage