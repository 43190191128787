import React from "react";
import {Box, Button, Flex} from "@chakra-ui/react";
import Link from "gatsby-link";
import {ArrowForwardIcon} from "@chakra-ui/icons";


const GoBack = ({})=>{



    return <Flex mt={"40px"} justifyContent={"center"}>


        <Link to={"/"}><Button rightIcon={<ArrowForwardIcon />} colorScheme={"text"} bg={"text.200"}  p={[2,3,4]} height={100} fontSize={["16px","19px","23px"]} fontFamily={"heading"}>Retourner à<br/> l'actualité politique</Button></Link>
    </Flex>
}

export default GoBack