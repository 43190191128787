
import React, {useEffect, useState} from "react";
import {Box, Container, Heading, Text} from "@chakra-ui/react";
import {HeadingAnalyse, HeadingAnalyseSection} from "../../tools/HeadingAnalyse";
import {getAnalyseData} from "../../../api/getAnalyseData";
import {LOG} from "../../../tools";
import {LineGraphGeneral} from "./LineGraphGeneral";
import {LineGraphWeekProp} from "./LineGraphWeekProp";
import PAnalyse from "../../tools/PAnalyse";
import BarPropGlobal from "./BarPropGlobal";
import ScatterCompareIntentions from "./ScatterCompareIntentions";
import MethodoUkraine from "./MethodoUkraine";

const PageStartUkraine = ({jsonAnalyse}) =>{
    const {title,id,authors} = jsonAnalyse

    const [dataGraphGeneral,setDataGraphGeneral] = useState({d:{},sd:"2022/02/01"})
    const [dataGraphProp,setDataGraphProp] = useState({d:{},sd:"2022/02/01",prop:{},intentions:{}})
    const versionAnalyse = 3
    useEffect(()=>{
        getAnalyseData("graph_general.json",id,versionAnalyse).then((d) =>{
            setDataGraphGeneral(d)
        })

        getAnalyseData("prop_candidats.json",id,versionAnalyse).then((d) =>{
            LOG("d",d)
            setDataGraphProp(d)

        })

    },[])

    return <Box>

         <HeadingAnalyseSection text={"La semaine où tout a commencé"}/>

        <LineGraphGeneral dataGraph={dataGraphGeneral}/>

        <PAnalyse content={<Text >La <strong>thématique Ukraine/Russie</strong> a clairement  pris un <strong>poids très important dans l'activité politique</strong> depuis le 21 Février (et encore plus après le 24 février). Les quelques jours qui ont suivi le lancement de l'invasion totale de l'Ukraine, <strong>plus de la moitié de l'activité générée sur twitter
                par les principaux candidats concernait la thématique Ukraine/Russie</strong>.   </Text>}/>




        <HeadingAnalyseSection text={"Les candidats ont adapté leur discours"}/>

        <LineGraphWeekProp dataGraph={dataGraphProp}/>

        <PAnalyse content={<Text >Intéressons nous plus particulièrement à la semaine du 21 février où l'actualité ukrainienne est devenu
            le sujet principal du débat politique :
            <strong> Au cours de la semaine  21-28 février, 27% de l'activité politique générée par les huit principaux candidats
                à l'élection évoquait la thématique de l'Ukraine/Russie</strong>.
                <br/> La plupart des candidats ont rapidement fait évoluer leur discours. Des disparités entre les candidats apparaissent tout de même avec seulement 12,5% de l'activité politique générée par Valérie Pécresse contre 39,6% pour Jean-Luc Mélenchon
 </Text>}/>

        <BarPropGlobal  dataPropGlobal={dataGraphProp["prop"]}/>


        <HeadingAnalyseSection text={"Une forte conséquence sur les intentions de vote"}/>

        <ScatterCompareIntentions  dataPropGlobal={dataGraphProp["prop"]} dataCompare={dataGraphProp["intentions"]}/>

        <PAnalyse content={<Text >
L'actualité en Ukraine a clairement modifié les intentions de vote mesurées par les
            différents instituts de sondage. Il est intéressant d’observer un <strong>lien
            entre le poids de la thématique Ukraine/Russie</strong> dans l’activité politique généré
            par le candidat <strong>et l’évolution des intentions de vote</strong> lors des 10 premiers jours
            qui ont suivi l'invasion en Ukraine. Ce lien qui, hormis pour la candidate Anne Hidalgo,
            montre que les candidats dont l’activité a fortement concerné la thématique Ukraine/Russie
            ont connu une augmentation de leurs intentions de vote.
            Bien évidemment, <strong>observer une corrélation ne veut pas dire causalité ! </strong>
            Des candidats auraient très bien pu décider de moins parler de cette actualité sachant
            l’impact négatif qu’elle allait avoir sur leur campagne et inversement pour d’autres.

        </Text>}/>

        <HeadingAnalyse text={"Méthodologie"}/>
        <MethodoUkraine/>



    </Box>
}

export default PageStartUkraine