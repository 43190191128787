import 'chartjs-adapter-date-fns';
import * as React from "react"
import {Bar, Line} from 'react-chartjs-2';
import {Box, Button, Flex, Text} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import theme from "../../../../@chakra-ui/gatsby-plugin/theme";
import {LOG} from "../../../tools";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoom from "chartjs-plugin-zoom"
import {addMinutes} from "../../../tools";
import {setGradientColor} from "../../../graph/tools";
import {getNameFromTagId, theme_candidats} from "../../../tag/tools";
import PGraphNote from "../../tools/PGraphNote";

const FONTSIZE_SM = 16
const FONTSIZE_MD = 20

const BarPropGlobal = ({dataPropGlobal})=> {

    const [listLabel,setListLabel] = useState(["hello","b"])
    const [datasets,setDatasets] = useState([])

    const dataIsLoaded = dataPropGlobal.hasOwnProperty(1)

    const listTagId = [13,26,39,8,11,18,1,7]
    useEffect(()=>{
        if(dataIsLoaded) {
            let dataSet_tmp = {"label":"proportion moyenne sur la semaine 21-28 Février 2022 ",
            backgroundColor:[],data:[]}
            let listLabelTmp = []
            listTagId.forEach(function(tagId) {
                let dataThistag = dataPropGlobal[tagId];
                listLabelTmp.push(getNameFromTagId(tagId))
                const colorL = theme_candidats[tagId]
                dataSet_tmp["backgroundColor"].push(`${colorL[500]}99`)
                dataSet_tmp["data"].push(dataThistag)

                // ...
            });

            setDatasets([dataSet_tmp])
            setListLabel(listLabelTmp)
        }
    },[dataPropGlobal])


    const data={
        labels : listLabel,
        datasets:datasets
    }
    const options ={responsive: true,maintainAspectRatio:false,
    plugins:{
        title:{display:true,text: ["Semaine du 21-28 Février",`Poids de la thématique Ukraine/Russie dans le débat politique généré par les candidats`],position:'bottom',
        font:{family:theme.fonts.heading,size:FONTSIZE_SM,weight:'700'}},
        datalabels: {
            align: 'top',
        anchor: 'end',
        color: theme.colors.text["500"],
        display: true,
        font:{family:theme.fonts.body,size:15,weight:'700'},
                    formatter: function(value, context) {
          return value + '%';
        }
      },
        legend:{
            display:false,
        }
    },
     scales:{
        y:{beginAtZero:true,display:false},
         x:{grid:{display:false}, ticks:{font:{family:theme.fonts.body,weight:'bold'}}}
     },
        layout:{
        padding:{left: 0,right: 0,top: 30,bottom: 0}
        }
    }
    LOG("data",data)
    return <Box>

        <Box height={400}><Bar width={"100%"} height={"300px"} data={data} options={options}  plugins={[ChartDataLabels]}/></Box>
        <PGraphNote content={<Box>
            <Text>. Entre le 21 Février et le 28 Février, 12.5% de l'activité politique générée par Valérie Pécresse concernait la thématique Ukraine/Russie  </Text>
        </Box>}/>
    </Box>

}


export default BarPropGlobal