

export const VOCAB_UKRAINE = ['#adopteuneukrainienne', '#africansinukraine', '#animalsofukraine',
       '#armeeukrainienne', '#conflitrusseukrainien',
       '#conflitrussieukraine', '#conflitukraine', '#conflitukrainien',
       '#crieukraine', '#crise_ukraine_russie', '#criseenukraine',
       '#criseukrainienne', '#fightforukraine', '#freeukraine',
       '#glorytoukraine', '#guerre_en_ukraine', '#guerreenukraine',
       '#guerrerussieukraine', '#guerreukraine', '#guerreukrainerussie',
       '#helpukraine', '#invasionofukraine', '#invasionukraine',
       '#istandforukraine', '#istandwithukraine', '#jenesuispasukraine',
       '#jesuisukraine', '#jesuisukrainien', '#kievukraine',
       '#melenchonukraine', '#natoinukrainenow', '#naziukraine',
       '#noflyzoneoverukraine', '#noussommestousukrainiens',
       '#nowarinukraine', '#otanrussieukraine', '#paixenukraine',
       '#paixukraine', '#peaceforukraine', '#peaceinukraine',
       '#peaceukraine', '#peupleukrainien', '#peupleuukrainien',
       '#prayforukrain', '#prayforukraine', '#prayingforukraine',
       '#proukrainiens', '#racismeenukraine', '#russiainvadedukraine',
       '#russiainvadesukraine', '#russianukraineconflict',
       '#russianukrainianwar', '#russianukrainwar', '#russiaukraine',
       '#russiaukraineconflict', '#russiaukrainecrisis',
       '#russiaukrainewar', '#russie_ukraine', '#russieukraine',
       '#russieukraineconflict', '#russieukraineconflit',
       '#russieukraineguerre', '#russieukrainewar', '#russoukrainien',
       '#saveukraine', '#sendnatotoukraine', '#slavaukraini',
       '#solidariteukraine', '#solidaritywithukraine', '#sosukraine',
       '#soutienukraine', '#specialeukrainefrance2',
       '#specialukrainefrance2', '#standforukraine',
       '#standingwithukraine', '#standwithukrain', '#standwithukraine',
       '#standwithukrainenow', '#standwithukrainians', '#staywithukraine',
       '#stopwarinukraine', '#supportukraine', '#ukrain', '#ukraina',
       '#ukraine', '#ukraine_russia', '#ukraineconflict', '#ukrainecrise',
       '#ukrainecrises', '#ukrainecrisis', '#ukraineenpaix',
       '#ukraineguerre', '#ukraineinvasion', '#ukrainekonflikt',
       '#ukrainekrieg', '#ukrainelibre', '#ukrainelive',
       '#ukrainenatomember', '#ukrainenazis', '#ukrainenews',
       '#ukrainepretexte', '#ukrainerussia', '#ukrainerussiaconflict',
       '#ukrainerussiacrisis', '#ukrainerussianwar', '#ukrainerussiawar',
       '#ukrainerussie', '#ukrainerussiecrise', '#ukrainerussieguerre',
       '#ukrainerussiewar', '#ukraineunderattack', '#ukraineunderattak',
       '#ukrainewar', '#ukrainewillresist', '#ukrainian', '#ukrainians',
       '#ukrainien', '#ukrainienne', '#ukrainiennes', '#ukrainiens',
       '#ukrainisation', '#ukrainne', '#ukrainrussiawar', '#warinukraine',
       '#westandwithukraine', 'dukraine', 'lukraine', 'russiaukraine',
       'russoukrainien', 'ukrain', 'ukraine', 'ukraine:', 'ukraines',
       'ukraini', 'ukrainian', 'ukrainians', 'ukrainie', 'ukrainien',
       'ukrainiene', 'ukrainienes', 'ukrainienne', 'ukrainiennes',
       'ukrainiens', 'ukrainiser', 'ukraino']


export const VOCAB_RUSSIE = ['#banrussiafromswift', '#bielorussia', '#bielorussie',
       '#bieolorussie', '#boycottrussia', '#conflitotanrussie',
       '#conflitrussieukraine', '#crise_ukraine_russie',
       '#fillonenrussie', '#fillonrussie', '#gorussia',
       '#guerrerussieukraine', '#guerreukrainerussie',
       '#helprussiansgetridofputin', '#kremlinrussia_e', '#larussie',
       '#natorussiawar', '#niusa_nirussie', '#nowarwithrussia',
       '#oprussia', '#otanrussieukraine', '#prorussia', '#russia',
       '#russiagohome', '#russiainvadedukraine', '#russiainvadesukraine',
       '#russian', '#russianaggression', '#russianarmy',
       '#russianinvasion', '#russianukraineconflict',
       '#russianukrainianwar', '#russianukrainwar', '#russiatoday',
       '#russiaucraina', '#russiaukraine', '#russiaukraineconflict',
       '#russiaukrainecrisis', '#russiaukrainewar', '#russiawakeup',
       '#russie', '#russie_ukraine', '#russieafrique', '#russieotan',
       '#russieukraine', '#russieukraineconflict',
       '#russieukraineconflit', '#russieukraineguerre',
       '#russieukrainewar', '#russieunie', '#sanctionrussianow',
       '#sanctionsrussianow', '#sanctionsrussie', '#soutienrussie',
       '#standwithrussia', '#stoprussia', '#stoprussianaggression',
       '#stoprussianattack', '#stoprussianfascism', '#stoptotalrussie',
       '#swiftoffrussia', '#ucrainarussia', '#ucraniarussia',
       '#ukainerussia', '#ukraine_russia', '#ukrainerussia',
       '#ukrainerussiaconflict', '#ukrainerussiacrisis',
       '#ukrainerussianwar', '#ukrainerussiawar', '#ukrainerussie',
       '#ukrainerussiecrise', '#ukrainerussieguerre', '#ukrainerussiewar',
       '#ukrainrussiawar', '#urkrainerussie', 'beliorussie',
       'bielorussie', 'biolorussie', 'larussie', 'prussien', 'prussiens',
       'rtrussian', 'russi', 'russia', 'russian', 'russians',
       'russiaukraine', 'russie', 'russies']