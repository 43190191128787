
import React, {useEffect, useState} from "react";
import {Box, Container, Heading, Text} from "@chakra-ui/react";
import {HeadingAnalyse, HeadingAnalyseSection} from "../../tools/HeadingAnalyse";
import {getAnalyseData} from "../../../api/getAnalyseData";
import {LOG} from "../../../tools";
import PAnalyse from "../../tools/PAnalyse";
import TableDesciptif from "./TableDesciptif";
import LineGraphPropPropag from "./LineGraphPropPropag";
import {LineGraphUsersByDay} from "./LineGraphUsersByDay";


const PageNetwork = ({jsonAnalyse}) =>{
    const {title,id,authors} = jsonAnalyse

    const [dataStatDesc,setDataStatDesc] = useState({d:{},sd:"2022/02/01"})
    const [dataJsonProp,setDataJsonProp] = useState({})
    const [dataUsersByDay,setDataUsersByDay] = useState({d:{},sd:"2022/01/01"})
    const versionAnalyse = 3
    useEffect(()=>{
        getAnalyseData("stat_desc.json",id,versionAnalyse).then((d) =>{
            setDataStatDesc(d)

        })

        getAnalyseData("json_prop.json",id,versionAnalyse).then((d) =>{
            setDataJsonProp(d)

        })
        getAnalyseData("users_by_day.json",id,versionAnalyse).then((d) =>{
            setDataUsersByDay(d)
            LOG("D",d)
        })


    },[])

    return <Box>

         <PAnalyse content={<Text>Les principaux candidats sont devenus des experts dans l’utilisation des réseaux sociaux : Twitter reste encore principalement utilisé en ce qui concerne la politique. De nombreuses polémiques interviennent en mettant en avant que certains candidats “abuseraient” de Twitter.
             Une série d'analyses sera réalisée pour essayer de donner un éclairage sur l’utilisation de Twitter par les principaux candidats. Cette première analyse donne la vision d'ensemble de cette l'utilisation.
</Text>}/>

         <HeadingAnalyseSection text={"Tous experts !"}/>

        <TableDesciptif jsonData={dataStatDesc}/>
        <PAnalyse content={<Text>Il ne se passe pas un jour sans une activité de candidats sur twitter. Ils n'ont cependant pas tous la même activité. Avec une moyenne de 3 posts par jour, Emmanuel Macron a le compte le moins productif mais qui provient sans doute de son cas particulier de compte de président actuel. Il s’est d’ailleurs fait reprendre sur ce point (Envoyer un tweet de candidat depuis ce compte lié à la fonction de président)<br/>
            La diffusion du candidat Emmanuel Macron est donc légèrement différente des autres candidats. Au contraire, <strong>Jean Luc Melenchon et Eric Zemmour sont très actifs</strong> avec une moyenne de 25-26 Posts par jour ! <strong>Eric Zemmour génère  cependant clairement plus d’activité</strong> que les autres candidats, avec chaque semaine en moyenne 26 500 utilisateurs qui réagissent au contenu diffusé par le candidat.
        </Text>}/>
        <HeadingAnalyseSection text={"Des Tweets qui se propagent globalement de la même façon !"}/>
        <LineGraphPropPropag dataGraph={dataJsonProp}/>

        <PAnalyse content={<Text>Si on analyse la propagation d’<strong>un tweet de candidat, en deux heures celui-ci recevra déjà la moitié de l'ensemble de ses retweets</strong>.
            On observe qu’en moyenne les tweets se propagent de façon à peu près équivalentes entre les différents candidats. En 2 heures en moyenne, Les tweets de Marine le Pen ont déjà généré 55,5% de leur activité, contre  45,8% pour les tweets d’Emmanuel Macron.<br/>
        Je n'ai donc pas observé en moyenne (j'insiste sur le en moyenne) d'effet comme quoi certains candidats utiliseraient des bots pour gonfler très rapidement leur audience.
            La distribution minute par minute de l'activité généré par les tweets ne semblent pas montrer plus de différences entre les candidats que de différence entre les tweets d'un même candidat. <br/>
            🤔Pour essayer d'analyser plus en détail la propagation des tweets,
            il faudra sans doute regarder les comportements des comptes utilisateurs qui retweetent pour peut-être faire apparaitre la critique d'abus fait à certains candidats (utilisation de comptes pour retweeter quasi-automatiquement du contenu).
    </Text>}/>
         <LineGraphUsersByDay dataGraph={dataUsersByDay}/>

        <PAnalyse content={<Text>Il reste cependant assez clair que <strong>chaque jour un nombre important d'utilisateurs différents réagissent aux posts d'Eric Zemmour</strong>.
            Ce nombre oscillant entre 7000 et 11000 reste <strong>bien supérieur aux autres candidats</strong>.
            Seul Emmanuel Macron a réussi à avoir une activité quotidienne plus importante depuis le début de l'invasion en Ukraine le 24 Février (30 000 utilisateurs ce jour).  </Text>}/>

    </Box>
}

export default PageNetwork