import React from "react";
import {Box} from "@chakra-ui/react";


const PGraphNote = ({content})=>{



    return <Box p={[1,2,3]}>
        <Box fontSize={"12px"} color={"text.300"}>Notes : </Box>
        <Box fontSize={"14px"} color={"text.200"} pl={1} pr={1}>
        {content}
            </Box>
    </Box>
}

export default PGraphNote