import 'chartjs-adapter-date-fns';
import * as React from "react"
import { Line } from 'react-chartjs-2';
import {Avatar, Box, Button, Flex, Text, useMediaQuery} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import theme from "../../../../@chakra-ui/gatsby-plugin/theme";
import {LOG} from "../../../tools";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoom from "chartjs-plugin-zoom"
import {addMinutes} from "../../../tools";
import {setGradientColor} from "../../../graph/tools";
import {getNameFromTagId, getUrlImgFromTagId, theme_candidats} from "../../../tag/tools";
import {motion} from "framer-motion";
import PGraphNote from "../../tools/PGraphNote";

const FONTSIZE_SM = 12
const FONTSIZE_MD = 15
const MotionAvatar = motion(Avatar)
const MotionBox = motion(Box)

export const LineGraphPropPropag = ({dataGraph})=> {
        const ref = useRef();
    const jsonValue = dataGraph
    const dataIsLoaded = jsonValue.hasOwnProperty("all")
    const nbValue = dataIsLoaded?jsonValue["all"].length:0

    const [listLabel,setListLabel] = useState([])
    const [datasets,setDatasets] = useState([])
    const [listValueData,setListValueData] = useState([])
      const [nameHover,setNameHover] = useState("")
    const [objectSetTagShown,setObjectSetTagShown] = useState(new Set())
    const listTagId = [1, 8, 13, 18, 7, 11, 26, 39]

    const [jsonTagIdToIndexDataset,setJsonTagIdToIndexDataset] = useState({})
    useEffect(()=>{
        if(nbValue>0) {
            let list_label = []
            const listValue = jsonValue["all"]
            listValue.forEach((value, i) => {
                list_label.push((i+1)*2)
            })
            setListLabel(list_label)
            let dataset_tmp = []
            let numTag = 1
            let listDataValueTmp = []
        listDataValueTmp.push(jsonValue["all"])
            dataset_tmp.push(
                    {
             type:'line',
        data:  listValue,
        label: "moyenne",
        lineTension:0.1,order:100,borderWidth:4,
        pointRadius:0,
        borderDash:[0,0],
        borderColor: theme.colors.gray[500],
            backgroundColor:setGradientColor( ref.current.canvas, theme.colors.gray[200], 100),
          fill:true,
                 }
                )
        let jsonTagIdToIndexDataset_tmp = {}
            listTagId.map((tagId)=>{
                numTag+=1
                jsonTagIdToIndexDataset_tmp[tagId] = dataset_tmp.length
                const colorL = theme_candidats[tagId]
                dataset_tmp.push(
                    {
             type:'line',
        data:  jsonValue[tagId],
        label: getNameFromTagId(tagId),
        lineTension:0.4,order:numTag,borderWidth:0.3,
        pointRadius:0,
        borderDash:[0,0],
        borderColor: colorL[500],
            backgroundColor:setGradientColor( ref.current.canvas, colorL[500], 400),
          fill:false,
                 }
                )
            })

            setListValueData(listDataValueTmp)
            setDatasets(dataset_tmp)
            setJsonTagIdToIndexDataset(jsonTagIdToIndexDataset_tmp)
        }
    },[dataGraph])


    useEffect(()=>{
        const chart = ref.current
        if(chart!= null && chart.data.datasets.length>0) {
            let colorL =null
            let indexDataset = null
            if(jsonValue.hasOwnProperty(nameHover) ){
             colorL = theme_candidats[nameHover]
             indexDataset = jsonTagIdToIndexDataset[nameHover]
            chart.data.datasets[indexDataset].borderColor =  colorL[500]
            chart.data.datasets[indexDataset].fill =  true
            chart.data.datasets[indexDataset].order =  0
            chart.data.datasets[indexDataset].borderWidth =  4
            }

            objectSetTagShown.forEach((tagId)=>{
                colorL = theme_candidats[tagId]
                indexDataset = jsonTagIdToIndexDataset[tagId]
                chart.data.datasets[indexDataset].borderColor =  colorL[500]
            chart.data.datasets[indexDataset].fill =  true
            chart.data.datasets[indexDataset].order =  0
            chart.data.datasets[indexDataset].borderWidth =  4

            })
            if(colorL!=null){
                 chart.update()
            }

        }

    }, [nameHover,objectSetTagShown])


const data = {
        labels: listLabel,
            datasets: datasets
    }

        const zoomOptions = {
  zoom: {
    wheel: {
      enabled: true,
        speed:0.3
    },
    pinch: {
      enabled: true,
    },
      drag:{
        enabled:true,
          backgroundColor:'rgba(75,75,75,0.3)'
      },
    mode: 'x',
  },
  pan: {
    enabled: true,
    mode: 'x',
  },
            limits: {
          x: {min: listLabel[0], max: listLabel[listLabel.length - 1]}}
};

const options = {responsive: true,
    animations:{
    numbers: { duration: 0 },
  colors: {
    type: "color",
    duration: 1000,
    //from: "transparent",
  }
    },
    maintainAspectRatio:false,
    scales: {
      x:{grid: {
                display:false,
                    drawBorder: true,
            },
          ticks: {
              stepSize:15,
      maxRotation: 0,

      },
          title: {    display: true, text:"temps écoulé en minute depuis la publication du tweet"}
            },
        y: {
          beginAtZero: true,
      display: true,
        ticks: {   display: true,font:{family:theme.fonts.heading,size:FONTSIZE_SM,weight:'700'}     },
        grid: {  display:false,   drawBorder: true,  },
      title: {    display: true, text:"Proportion des retweets déjà obtenus"}
    }
    },
        plugins: {
    title:{display:true,text:[`Proportion de l'activité générée par un tweet`,`au cours du temps`],position:'bottom',
        font:{family:theme.fonts.heading,size:FONTSIZE_MD,weight:'700'}},
     tooltip:{
            enabled:true,
            intersect:false,
            mode:'nearest',
            interaction: {
            mode: 'index',
                axis:'x'
        },
            callbacks: {
                title: function(tooltipItem, data) {
                    return  `Proportion de l'ensemble des retweets après ${tooltipItem[0].label}minutes`
                },
            }

        },
       datalabels: {
        align:'top',anchor:'end',
            display:false,
         backgroundColor: function(context) {
          return theme.colors.bg["900"];
        },
           borderColor:theme.colors.text["500"],
        borderRadius: 4,
        color: theme.colors.text["500"],
        font: {weight: 'bold'},
        formatter: Math.round(2),padding:5
       },
        legend:{
        position:"bottom",
        labels:{
            font:{family:theme.fonts.heading,size:14,weight:'400'},
        filter: function(item, chart) {
          return true;
        }
        }}
        },
            layout:{
        padding:{left: 0,right: 0,top: 20,bottom: 0}
        }
    }

    return <Box height={"100%"} width={"100%"}>

        <Flex width={"100%"} justifyContent={"center"}>
         <Box  height={500} width={600} maxWidth={"100%"}><Line  ref={ref} height={500} data={data} options={options}
                                  plugins={[ChartDataLabels]}
        /></Box>
            </Flex>
    </Box>
}

export default LineGraphPropPropag