import 'chartjs-adapter-date-fns';
import * as React from "react"
import { Line } from 'react-chartjs-2';
import {Avatar, Box, Button, Flex, Text, useMediaQuery} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import theme from "../../../../@chakra-ui/gatsby-plugin/theme";
import {LOG} from "../../../tools";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoom from "chartjs-plugin-zoom"
import {addMinutes} from "../../../tools";
import {setGradientColor} from "../../../graph/tools";
import {getNameFromTagId, getUrlImgFromTagId, theme_candidats} from "../../../tag/tools";
import {motion} from "framer-motion";
import PGraphNote from "../../tools/PGraphNote";

const FONTSIZE_SM = 12
const FONTSIZE_MD = 15
const MotionAvatar = motion(Avatar)
const MotionBox = motion(Box)

export const LineGraphUsersByDay = ({dataGraph})=> {

        const ref = useRef();
    const [isLowerThan780] = useMediaQuery("(max-width: 780px)")
    const jsonValue = dataGraph.d
    const dataIsLoaded = jsonValue.hasOwnProperty(1)
    const nbValue = dataIsLoaded?jsonValue[1].length:0
    const listNull = Array(nbValue).fill(0)
    const startDateStr = dataGraph.sd


    const [listLabel,setListLabel] = useState([])
    const [datasets,setDatasets] = useState([])
    const [listValueData,setListValueData] = useState([])
    const startDate = new Date(startDateStr.replace(/-/g, "/"))
    const stepWindow = 60*24
      const [nameHover,setNameHover] = useState("")
    const [objectSetTagShown,setObjectSetTagShown] = useState(new Set())
    const listTagId = [1, 8, 13, 18, 7, 11, 26, 39]

    const [jsonTagIdToIndexDataset,setJsonTagIdToIndexDataset] = useState({})
    useEffect(()=>{
        if(nbValue>0) {
            let list_label = []
            const listValue = jsonValue[1]
            listValue.forEach((value, i) => {
                list_label.push(addMinutes(startDate, i * stepWindow))
            })
            setListLabel(list_label)
            let dataset_tmp = []
            let numTag = 1
            let listDataValueTmp = []
        //listDataValueTmp.push(jsonValue["all"])

        let jsonTagIdToIndexDataset_tmp = {}
            listTagId.map((tagId)=>{
                numTag+=1
                jsonTagIdToIndexDataset_tmp[tagId] = dataset_tmp.length
                const colorL = theme_candidats[tagId]
                dataset_tmp.push(
                    {
             type:'line',
        data:  jsonValue[tagId],
        label: getNameFromTagId(tagId),
        lineTension:0.4,order:numTag,borderWidth:1,
        pointRadius:0,
        borderDash:[0,0],
        borderColor: colorL[500],
            backgroundColor:setGradientColor( ref.current.canvas, colorL[500], 400),
          fill:false,
                 }
                )
            })

           // setListValueData(listDataValueTmp)
            setDatasets(dataset_tmp)
            setJsonTagIdToIndexDataset(jsonTagIdToIndexDataset_tmp)
        }
    },[dataGraph])


    useEffect(()=>{
        const chart = ref.current
        if(chart!= null && chart.data.datasets.length>0) {
            let colorL =null
            let indexDataset = null
            if(jsonValue.hasOwnProperty(nameHover) ){
             colorL = theme_candidats[nameHover]
             indexDataset = jsonTagIdToIndexDataset[nameHover]
            chart.data.datasets[indexDataset].borderColor =  colorL[500]
            chart.data.datasets[indexDataset].fill =  true
            chart.data.datasets[indexDataset].order =  0
            chart.data.datasets[indexDataset].borderWidth =  4
            }

            objectSetTagShown.forEach((tagId)=>{
                colorL = theme_candidats[tagId]
                indexDataset = jsonTagIdToIndexDataset[tagId]
                chart.data.datasets[indexDataset].borderColor =  colorL[500]
            chart.data.datasets[indexDataset].fill =  true
            chart.data.datasets[indexDataset].order =  0
            chart.data.datasets[indexDataset].borderWidth =  4

            })
            let DO_update_axe = false
            if(objectSetTagShown.has(1)){
                if(  chart.options.scales.y.max ==15000){
                    DO_update_axe =true
                     chart.options.scales.y.max = 35000
                }
            }else{
                if(  chart.options.scales.y.max ==35000){
                    DO_update_axe =true
                     chart.options.scales.y.max = 15000
                }
            }


            if(colorL!=null || DO_update_axe){
                 chart.update()
            }

        }

    }, [nameHover,objectSetTagShown])


const data = {
        labels: listLabel,
            datasets: datasets
    }

        const zoomOptions = {
  zoom: {
    wheel: {
      enabled: true,
        speed:0.3
    },
    pinch: {
      enabled: true,
    },
      drag:{
        enabled:true,
          backgroundColor:'rgba(75,75,75,0.3)'
      },
    mode: 'x',
  },
  pan: {
    enabled: true,
    mode: 'x',
  },
            limits: {
          x: {min: listLabel[0], max: listLabel[listLabel.length - 1]}}
};

const options = {responsive: true,
    animations:{
    numbers: { duration: 0 },
  colors: {
    type: "color",
    duration: 1000,
    //from: "transparent",
  }
    },
    maintainAspectRatio:false,
    scales: {
      x:{grid: {
                display:false,
                    drawBorder: true,
            },type: 'time',
        distribution: 'linear',
          time:{
          unit:"day",
              displayFormats:{day:"dd-MMM"}
          },
          ticks: {
      autoSkip: false,
      maxRotation: 0,

      major: {
        enabled: true
      },
      font: function(context) {
        if (context.tick && context.tick.major) {
            return {
                weight: 'bold',
                size:FONTSIZE_SM,
                family:theme.fonts.heading
            }
        }else{return{size:FONTSIZE_SM}}}
      }
            },
        y: {
          beginAtZero: true,max:15000,
      display: true,
        ticks: {   display: true,font:{family:theme.fonts.heading,size:FONTSIZE_SM,weight:'700'}     },
        grid: {  display:false,   drawBorder: true,  },
      title: {    display: true}
    }
    },
        plugins: {
    title:{display:true,text:[`Nombre d'utilisateurs différents`,`Relayant chaque jour l'activité des candidats`],position:'bottom',
        font:{family:theme.fonts.heading,size:FONTSIZE_MD,weight:'700'}},
     tooltip:{
            enabled:true,
            intersect:false,
            mode:'nearest',
            interaction: {
            mode: 'index',
                axis:'x'
        },
            callbacks: {
                title: function(tooltipItem, data) {
                    return  `Nombre d'utilisateurs différents \n ${tooltipItem[0].label}`
                },
            }

        },
       datalabels: {
        align:'top',anchor:'end',
            display:false,
         backgroundColor: function(context) {
          return theme.colors.bg["900"];
        },
           borderColor:theme.colors.text["500"],
        borderRadius: 4,
        color: theme.colors.text["500"],
        font: {weight: 'bold'},
        formatter: Math.round(2),padding:5
       },
        legend:{
        position:"bottom",
        labels:{
            font:{family:theme.fonts.heading,size:12,weight:'400'},
        filter: function(item, chart) {
          return false;
        }
        }}
        },
            layout:{
        padding:{left: 0,right: 0,top: 20,bottom: 0}
        }
    }


    return <Box height={"100%"} width={"100%"}>

        <Flex justifyContent={"center"} flexWrap={"wrap"}>
            {listTagId.map((tagId) =>
                <TagLegend key={tagId} tagId={tagId} hoverTag={(value)=> {
                    setNameHover(value)
                }}
                    clickTag={()=>{
                        let objectTmp = new Set(objectSetTagShown)
                        if( objectTmp.has(tagId)){
                            objectTmp.delete(tagId)
                        }else{
                            objectTmp.add(tagId)
                        }
                        setObjectSetTagShown(objectTmp)
                        LOG("CLICK",objectTmp,objectSetTagShown,nameHover,objectSetTagShown.has(tagId) || nameHover==tagId)
                    }}
                           isMobile={isLowerThan780}
                   isActive={objectSetTagShown.has(tagId) || nameHover==tagId}

                />

            )}

       </Flex>
         <Box  height={400}><Line width={"100%"} ref={ref} height={600} data={data} options={options}
                                  plugins={[ChartDataLabels]}
        /></Box>
        <PGraphNote content={<Box><Text>. Vous pouvez sélectionner un candidat en cliquant sur son icone.</Text>
            <Text>. Le 24 Février, 30 153 utilisateurs différents ont réagi aux tweets d'emmanuel macron.   </Text>
        </Box>}/>
    </Box>
}

const TagLegend = ({hoverTag,tagId,isActive,clickTag,isMobile}) => {
    const colorT = theme_candidats[tagId]
const urlImg = getUrlImgFromTagId(tagId,50)
    return <MotionBox
        borderColor={"bg.900"} borderWidth={"1px"} borderStyle={"solid"}
        borderRadius={"5px"}
        animate={isActive?"active":"no"}
        variants={{
        no:{opacity:0.5,backgroundColor:theme.colors["bg"]["500"]},
        active:{opacity:1,backgroundColor:colorT["500"]},
        }
        }
        cursor={"pointer"}
        onMouseEnter={() => !isMobile && hoverTag(tagId)}
        onMouseLeave={() => !isMobile && hoverTag("")}

        onClick={()=>clickTag()} m={1} p={1}>
        <MotionAvatar
            sx={{filter: 'grayscale(40%)', opacity: 1}}
            src={urlImg} width={50} height={50}/>
        </MotionBox>
}