import {Box, Flex, Text} from "@chakra-ui/react";

import React from "react";

const PAnalyse = ({content})=>{


    return <Flex width={"100%"} justifyContent={"center"} pb={[3,4,5]}>
        <Box maxW={1050} width={"100%"} bg={"bg.300"} borderColor={"bg.900"} borderStyle={"solid"}
        //borderLeftWidth={"1px"} borderRightWidth={"1px"} p={[1,2,3]}
        p={[1,2,3]}
     m={[1,2,3]}
             borderRadius={"5px"}
             boxShadow={"card"}
             fontSize={["16px","20px","22px","24px"]}
             fontFamily={""}
             textAlign={"justify"}
        >
            {content}
        </Box>

    </Flex>
}

export default PAnalyse