import React from "react";
import {Box, Flex, Heading} from "@chakra-ui/react";


export const HeadingAnalyse = ({text})=>{

    return <Heading mt={10} as={"h2"} fontFamily={"body"} fontSize={"30px"}>{text}</Heading>
}




export const HeadingAnalyseSection = ({text,idName}) => {
    const heightBar ='3px'
    return <Flex id={idName} sx={{alignItems:'center',p:'4px', pt:'25px',lineHeight:1.25,mt:4,mb:4}}>
            <Box sx={{flexGrow:1,height:heightBar,opacity:0.3,bg:'border'}}></Box>
            <Heading as={"h2"}  sx={{fontFamily:'body',fontWeight:'bold', textAlign:["center","left"],
                fontSize:["22px","26px","28px"],
                p:'4px',mb:'5px',color:'text.200',height:"40px",maxWidth:'calc(100% - 15px)'}}>{text}</Heading>
            <Box sx={{flexGrow:1,height:heightBar,opacity:0.3,bg:'border'}}></Box>
    </Flex>
}

