import 'chartjs-adapter-date-fns';
import * as React from "react"
import {Bar, Line, Scatter} from 'react-chartjs-2';
import {Box, Button, Flex, Link, Text} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import theme from "../../../../@chakra-ui/gatsby-plugin/theme";
import {LOG} from "../../../tools";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoom from "chartjs-plugin-zoom"
import {addMinutes} from "../../../tools";
import {setGradientColor} from "../../../graph/tools";
import {getNameFromTagId, theme_candidats} from "../../../tag/tools";
import PGraphNote from "../../tools/PGraphNote";

const FONTSIZE_SM = 16
const FONTSIZE_MD = 20

const DATASIMPLE = [{x:12,y:15,l:"Bonjour"},{x:10,y:8,l:"aurevoir"},{x:11,y:10,l:"MACRON"}]

const ScatterCompareIntentions = ({dataPropGlobal,dataCompare})=> {

    const [listLabel,setListLabel] = useState(["hello","b"])
    const [datasets,setDatasets] = useState([])

    const dataIsLoaded = dataPropGlobal.hasOwnProperty(1)

    const listTagId = [13,26,39,8,11,18,1,7]
    useEffect(()=>{
        if(dataIsLoaded) {

            let datasetRegression = {
                "label":"a",
                data:[{"x":12.5,"y":-6.98},{"x":40,"y":7.63}],
                borderWidth:2,
                pointRadius:0,
                borderColor:theme.colors.gray[900],
                showLine:true
            }

            let dataSet_tmp = {"label":"proportion moyenne sur la semaine 21-28 Février 2022 ",
                showLine:false,
            backgroundColor:[],data:[]}
            let listLabelTmp = []
            listTagId.forEach(function(tagId) {
                let jsonData = {}
                jsonData["x"] = dataPropGlobal[tagId]
                jsonData["y"] = dataCompare[tagId]
                jsonData["l"] = getNameFromTagId(tagId)
                listLabelTmp.push(getNameFromTagId(tagId))
                const colorL = theme_candidats[tagId]
                dataSet_tmp["backgroundColor"].push(`${colorL[500]}99`)
                dataSet_tmp["data"].push(jsonData)

                // ...
            });

            setDatasets([datasetRegression,dataSet_tmp])
            setListLabel(listLabelTmp)
        }
    },[dataPropGlobal])


    const data={
        labels : listLabel,
        datasets:datasets
    }
    const options ={responsive: true,maintainAspectRatio:false,
    plugins:{
        title:{display:true,text: ["Lien entre thématique Ukraine/Russie et intentions de vote"],position:'bottom',
        font:{family:theme.fonts.heading,size:FONTSIZE_SM,weight:'700'}},
        datalabels: {
            align: 'bottom',
        anchor: 'start',
            offset:2,
        //backgroundColor:`${theme.colors.bg[700]}`,
        color: theme.colors.text["500"],
        display: true,
        font:{family:theme.fonts.body,size:15,weight:'700'},

                    formatter: function (value,context) {
        return context.datasetIndex==1?value.l:"";
        }
      },
        legend:{
            display:false,
        },
        tooltip: {
      mode: 'nearest',
      enabled:true,
        filter:function(tooltipItem, data) {
              return tooltipItem.datasetIndex==1
        },
      intersect: false,
      callbacks: {
           title: function(tooltipItem, data) {
                if( tooltipItem==null || tooltipItem[0] ==null){
                    return ""
                }
               return tooltipItem[0].raw.l
           },
           label:function(tooltipItem){
                    return [`Evol intentions de vote  : ${tooltipItem.raw.y}% `,
                    `Poids Ukraine/Russie : ${tooltipItem.raw.x}%`] }
      }
    }
    },
     scales:{
        y:{beginAtZero:true,display:true,grid:{display:false},
            title:{display:true,text:"Évolution des intentions de votes entre le 20 février et le 3 mars",
                font:{family:theme.fonts.body,size:15,weight:'body'}}},
         x:{suggestedMax:40,grid:{display:false}, ticks:{font:{family:theme.fonts.body,weight:'bold'}},
         title:{display:true,text:"Poids de la thématique Ukraine/Russie la semaine du 21-28 Février",
                font:{family:theme.fonts.body,size:15,weight:'body'}}}
     },
        layout:{
        padding:{left: 0,right: 55,top: 30,bottom: 0}
        }
    }
    LOG("data",data)
    return <Box>
        <Box>
        <Scatter width={"100%"} height={"600px"} data={data} options={options}  plugins={[ChartDataLabels]}/>
        </Box>
        <PGraphNote content={<Box>
            <Text>. Entre le 21 Février et le 28 Février, 12.5% de l'activité politique générée par Valérie Pécresse concernait la thématique Ukraine/Russie.
                Les intentions de vote pour Valérie Pécresse ont baissé de 9% entre le 20 Février et le 3 mars
            </Text>
            <Text>. Les intentions de votes sont calculées en agrégant l'ensemble des sondages collecté par : <Link href={"https://github.com/nsppolls/nsppolls"}>NSPPolls</Link></Text>
        </Box>}/>
    </Box>

}


export default ScatterCompareIntentions