import 'chartjs-adapter-date-fns';
import * as React from "react"
import { Line } from 'react-chartjs-2';
import {Box, Button, Flex, Text} from "@chakra-ui/react";
import {useEffect, useRef, useState} from "react";
import theme from "../../../../@chakra-ui/gatsby-plugin/theme";
import {LOG} from "../../../tools";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoom from "chartjs-plugin-zoom"
import {addMinutes} from "../../../tools";
import {setGradientColor} from "../../../graph/tools";
import PGraphNote from "../../tools/PGraphNote";

const FONTSIZE_SM = 12
const FONTSIZE_MD = 15

export const LineGraphGeneral = ({dataGraph})=> {

        const ref = useRef();

    const jsonValue = dataGraph.d
    const dataIsLoaded = jsonValue.hasOwnProperty("prop")
    const nbValue = dataIsLoaded?jsonValue["prop"].length:0
    const listNull = Array(nbValue).fill(0)
    const startDateStr = dataGraph.sd


    const [listLabel,setListLabel] = useState([])
    const [datasets,setDatasets] = useState([])
    const [listValueData,setListValueData] = useState([])
    const startDate = new Date(startDateStr.replace(/-/g, "/"))
    const stepWindow = 60*1
      const [nameShow,setNameShow] = useState("prop")

    useEffect(()=>{
        if(nbValue>0) {
            let list_label = []
            const listValue = jsonValue[nameShow]
            listValue.forEach((value, i) => {
                list_label.push(addMinutes(startDate, i * stepWindow))
            })
            setListLabel(list_label)
            let dataset_tmp = []
            let numTag = 0
            let listDataValueTmp = []
        listDataValueTmp.push(jsonValue[nameShow])
            dataset_tmp.push(
                    {
             type:'line',
        data:  listValue,
        label: "moyenne",
        lineTension:0.4,order:0,borderWidth:2,
        pointRadius:0,
        borderDash:[0,0],
        borderColor: theme.colors.primary[500],
            backgroundColor:setGradientColor( ref.current.canvas, theme.colors.primary[500], 400),
          fill:true,
            datalabels: { offset:5,
                        align: 'top', anchor: 'end', font: {size:12,weight:'700'},
                        display: function (context) {
                            return (jsonDataLabels.hasOwnProperty(context.dataIndex))},//(context.dataIndex === context.dataset.data.length - 1); },
                formatter: function(value, context) {
                      return  jsonDataLabels[context.dataIndex];
                    }
                    }
                 }
                )
            setListValueData(listDataValueTmp)
            setDatasets(dataset_tmp)
        }
    },[dataGraph])

    const  jsonDataLabels = {
        504: "22 Février \nDébut de l'Invasion \nde l'est de l'Ukraine",
        560: "24 Février \nDébut de l'Invasion totale\n de l'Ukraine"
    }

    // useEffect(()=>{
    //     LOG("RUN NEW VERSIOn",nameShow)
    //     const chart = ref.current
    //     if(chart!= null && chart.data.datasets.length>0 && jsonValue.hasOwnProperty(nameShow)) {
    //         const newValues  =  [...jsonValue[nameShow]]
    //         chart.data.datasets[0].data = newValues
    //         chart.update()
    //
    //         LOG("update ",chart.data.datasets[0].data)
    //         LOG("jsonValue[nameShow]",jsonValue)
    //     }
    // }, [nameShow])


const data = {
        labels: listLabel,
            datasets: datasets
    }

        const zoomOptions = {
  zoom: {
    wheel: {
      enabled: true,
        speed:0.3,
        modifierKey:'ctrl' //null
    },
    pinch: {
      enabled: false,
    },
      drag:{
        enabled:true,
          backgroundColor:'rgba(75,75,75,0.3)'
      },
    mode: 'x',
  },
  pan: {
    enabled: false,
    mode: 'x',
  },
            limits: {
          x: {min: listLabel[0], max: listLabel[listLabel.length - 1], minRange:listLabel[50] - listLabel[0]}}
};

const options = {responsive: true,
    maintainAspectRatio:false,
    scales: {
      x:{grid: {
                display:false,
                    drawBorder: true,
            },type: 'time',
        distribution: 'linear',
          time:{
          unit:"day",
              displayFormats:{day:"dd"}
          },
          ticks: {
      autoSkip: false,
      maxRotation: 0,

      major: {
        enabled: true
      },
      font: function(context) {
        if (context.tick && context.tick.major) {
            return {
                weight: 'bold',
                size:FONTSIZE_SM,
                family:theme.fonts.heading
            }
        }else{return{size:FONTSIZE_SM}}}
      }
            },
        y: {
          beginAtZero: true,
      display: true,
        ticks: {   display: true,font:{family:theme.fonts.heading,size:FONTSIZE_SM,weight:'700'}     },
        grid: {  display:false,   drawBorder: true,  },
      title: {    display: true}
    }
    },
        plugins: {
    zoom:zoomOptions,
    title:{display:true,text:`Poids de la thématique Ukraine/Russie dans le débat politique`,position:'bottom',
        font:{family:theme.fonts.heading,size:FONTSIZE_MD,weight:'700'}},
     tooltip:{
            enabled:true,
            intersect:false,
            mode:'nearest',
            interaction: {
            mode: 'index',
                axis:'x'
        },
            callbacks: {
                title: function(tooltipItem, data) {
                    return  `Poids (en %) de la thématique Ukraine/Russie \n ${tooltipItem[0].label}`
                },
            }

        },
       datalabels: {
        align:'top',anchor:'end',
            display:false,
         backgroundColor: function(context) {
          return theme.colors.bg["900"];
        },
           borderColor:theme.colors.text["500"],
        borderRadius: 4,
        color: theme.colors.text["500"],
        font: {weight: 'bold'},
        formatter: Math.round(2),padding:5
       },
        legend:{
        position:"bottom",
        labels:{
            font:{family:theme.fonts.heading,size:12,weight:'400'},
        filter: function(item, chart) {
          return false;
        }
        }}
        },
            layout:{
        padding:{left: 0,right: 0,top: 80,bottom: 0}
        }
    }


    return <Box height={"100%"} width={"100%"}>
         <Box  height={400}><Line width={"100%"} ref={ref} height={400} data={data} options={options}
                                  plugins={[ChartDataLabels,zoom]}
        /></Box>
        <PGraphNote content={<Box><Text>Vous pouvez zoomer en selectionnant une zone ou en maintenant ctrl ( + scroll)</Text>
            <Text>Le 22 Février, entre 12h et 13h, 52% de l'activité politique générée par les principaux candidats concernait la thématique Ukraine/Russie  </Text>
        </Box>}/>
    </Box>
}